@font-face {
  font-family: "HelveticaNeueCyr-Roman";
  src: url("../fonts/HelveticaNeueCyr-Roman.woff2") format("woff2");
}

@font-face {
  font-family: "HelveticaNeueCyr-bold";
  src: url("../fonts/helveticaneuecyr-bold.woff2") format("woff2");
}

@font-face {
  font-family: "open-sans-semibold";
  src: url("../fonts/open-sans-semibold.woff2") format("woff2");
}

@font-face {
  font-family: "HelveticaNeueCyr-Medium";
  src: url("../fonts/HelveticaNeueCyr-Medium.woff2") format("woff2");
}

body {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-1 {
  margin-top: 1rem;
}
.chose__screen {
  padding-bottom: 156px;
  border-radius: 40px 40px 0 0;

  width: 375px;
  padding: 30px 25px;
  font-family: "HelveticaNeueCyr-Roman", sans-serif;
  background: #fefefe;
  /* border-radius: 40px 40px 0 0; */
  margin: 0 auto;

  /* left: 50%;
   top: 50%;
   z-index: 1;
   position: absolute;
   transform: translate3d(-50%, -50%, 0); */
}

.screen__tetle {
  position: relative;
  display: flex;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #060606;
  margin-bottom: 36px;
}

.screen__tetle img {
  position: absolute;
  left: 10%;
  top: 25%;
  height: 9px;
  width: 5.5px;
}

.screen__withdraw-profit {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.screen__withdraw-profit img {
  margin: 20px 0;
  width: 40px;
  height: 40px;
}

.screen__withdraw-profit-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 5px;
  font-family: "HelveticaNeueCyr-bold";
}

.screen__withdraw-profit-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.arrow-right img {
  height: 9px;
  width: 5.5px;
  transform: rotate(180deg);
}

.withdraw-profit_active {
  background: #ffffff;
  box-shadow: 0px 20px 64px rgba(0, 0, 0, 0.1);
  border-radius: 30px;

  position: relative;
  background: #1e1e1e;
  z-index: 0;
}

.withdraw-profit_active::before {
  content: "";
  background: #fff;
  border-radius: 28px;
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  z-index: -1;
}

.btn-black {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  background: #060606;
  border-radius: 50px;
  font-family: "open-sans-semibold";
}

/* Conclusion to the map */
.block__chose {
  display: flex;
  flex-direction: column;
}

.block__chose p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.block__chose_input {
  display: flex;
  align-items: center;
  padding: 12px 26px 12px 14px;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 59px;
}

.block__chose_input-img {
  width: 35px;
  text-align: center;
}

.delimiter {
  height: 22px;
  width: 2px;
  background-color: #f1f1f1;
  margin: 0 19px 0 16px;
}

.block__chose_input-btn {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  cursor: pointer;
}

.block__chose_input-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d2d2d;
}

.block__chose-gradient {
  position: relative;
  border-radius: 20px;
  background: #1e1e1e;
  z-index: 0;
}

.block__chose-gradient::before {
  content: "";
  background: #fff;
  border-radius: 18px;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
  z-index: -1;
}

#card__number,
#phone__number,
#amount__money,
#crypto__address {
  font-size: 16px;
  width: 100%;
  height: 100%;
}

.screen__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #a3a3a3;
}

.btn-gradient {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  color: #ffffff;
  font-family: "open-sans-semibold";
  font-size: 16px;
  line-height: 20px;
  background: #1e1e1e;
  border-radius: 50px;
}

/* Conclusion to the map END */

/* Withdrawal to crypto wallet */
.block__chose_money {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
}

.block__chose_money-text {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.block__chose_money-money {
  font-size: 16px;
  line-height: 19px;
  color: #a3a3a3;
}

/* Withdrawal to crypto wallet END */

/* chose__connection */
.chose__connection {
  width: 328px;
  padding: 40px 25px;
  font-family: "HelveticaNeueCyr-Romans", sans-serif;
  background: #fefefe;
  border-radius: 40px;
  box-shadow: 0px 20px 64px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;

  /* position: absolute;
   top: 50%;
   left: 50%;
   z-index: 1;
   transform: translate3d(-50%, -50%, 0); */
}

.block__chose_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 18px 12px 20px;
  height: 60px;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  font-family: "HelveticaNeueCyr-Medium";
  font-size: 18px;
  line-height: 24px;
  color: #060606;
  cursor: pointer;
}

.border-gradient-40 {
  position: relative;
  border-radius: 40px;
  background: #1e1e1e;
  z-index: 0;
}

.border-gradient-40::before {
  content: "";
  background: #fff;
  border-radius: 38px;
  position: absolute;
  top: 1.4px;
  left: 1.4px;
  bottom: 1.4px;
  right: 1px;
  z-index: -1;
}

/* chose__connection END */

/* info__window  */
.info__window {
  width: 328px;
  padding: 28px;
  font-family: "HelveticaNeueCyr-Romans", sans-serif;
  background: #fefefe;
  border-radius: 40px;
  box-shadow: 0px 20px 64px rgb(0 0 0 / 10%);
  text-align: center;

  margin: 0 auto;

  /* position: absolute;
   top: 50%;
   left: 50%;
   z-index: 1;
   transform: translate3d(-50%, -50%, 0); */
}

.info__window-title {
  margin: 36px 0 15px;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #060606;
}

.info__window-text {
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 65px;
}

.info__window-text span {
  font-weight: 700;
}

/* info__window END */
@media screen and (max-width: 400px) {
  .chose__screen {
    width: 320px;
    padding: 30px 8px;
  }

  .chose__connection {
    width: 320px;
    padding: 40px 8px;
  }

  .info__window {
    width: 300px;
  }
}

@font-face {
  font-family: "HelveticaNeueCyr-Roman";
  src: url("../fonts/HelveticaNeueCyr-Roman.woff2") format("woff2");
}

@font-face {
  font-family: "HelveticaNeueCyr-bold";
  src: url("../fonts/helveticaneuecyr-bold.woff2") format("woff2");
}

@font-face {
  font-family: "open-sans-semibold";
  src: url("../fonts/open-sans-semibold.woff2") format("woff2");
}

@font-face {
  font-family: "HelveticaNeueCyr-Medium";
  src: url("../fonts/HelveticaNeueCyr-Medium.woff2") format("woff2");
}

body {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.chose__screen {
  width: 375px;
  padding: 30px 25px;
  font-family: "HelveticaNeueCyr-Roman", sans-serif;
  background: #fefefe;
  /* border-radius: 40px 40px 0 0; */
  box-shadow: 0px 20px 64px rgb(0 0 0 / 10%);
  margin: 0 auto;

  /* left: 50%;
  top: 50%;
  z-index: 1;
  position: absolute;
  transform: translate3d(-50%, -50%, 0); */
}

.screen__tetle {
  position: relative;
  display: flex;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #060606;
  margin-bottom: 36px;
}

.screen__tetle img {
  position: absolute;
  left: 10%;
  top: 25%;
  height: 9px;
  width: 5.5px;
}

.screen__withdraw-profit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.screen__withdraw-profit img {
  margin: 20px 0;
  width: 40px;
  height: 40px;
}

.screen__withdraw-profit-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 5px;
  font-family: "HelveticaNeueCyr-bold";
}

.screen__withdraw-profit-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.arrow-right img {
  height: 9px;
  width: 5.5px;
  transform: rotate(180deg);
}

.withdraw-profit_active {
  background: #ffffff;
  box-shadow: 0px 20px 64px rgba(0, 0, 0, 0.1);
  border-radius: 30px;

  position: relative;
  background: #1e1e1e;
  z-index: 0;
}

.withdraw-profit_active::before {
  content: "";
  background: #fff;
  border-radius: 28px;
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  z-index: -1;
}

.btn-black {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  background: #060606;
  border-radius: 50px;
  font-family: "open-sans-semibold";
}

/* Conclusion to the map */
.block__chose {
  display: flex;
  flex-direction: column;
}

.block__chose p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.block__chose_input {
  display: flex;
  align-items: center;
  padding: 12px 26px 12px 14px;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 59px;
}

.block__chose_input-img {
  width: 35px;
  text-align: center;
}

.delimiter {
  height: 22px;
  width: 2px;
  background-color: #f1f1f1;
  margin: 0 19px 0 16px;
}

.block__chose_input-btn {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  cursor: pointer;
}

.block__chose_input-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d2d2d;
}

.block__chose-gradient {
  position: relative;
  border-radius: 20px;
  background: #1e1e1e;
  z-index: 0;
}

.block__chose-gradient::before {
  content: "";
  background: #fff;
  border-radius: 18px;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
  z-index: -1;
}

#card__number,
#phone__number,
#amount__money,
#amount__money_new,
#crypto__address {
  font-size: 16px;
  width: 100%;
  height: 100%;
}

.screen__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #a3a3a3;
}

.btn-gradient {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  color: #ffffff;
  font-family: "open-sans-semibold";
  font-size: 16px;
  line-height: 20px;
  background: #1e1e1e;
  border-radius: 50px;
}

/* Conclusion to the map END */

/* Withdrawal to crypto wallet */
.block__chose_money {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
}

.block__chose_money-text {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.block__chose_money-money {
  font-size: 16px;
  line-height: 19px;
  color: #a3a3a3;
}

/* Withdrawal to crypto wallet END */

/* chose__connection */
.chose__connection {
  width: 328px;
  padding: 40px 25px;
  font-family: "HelveticaNeueCyr-Romans", sans-serif;
  background: #fefefe;
  border-radius: 40px;
  box-shadow: 0px 20px 64px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;

  /* position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate3d(-50%, -50%, 0); */
}

.block__chose_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 18px 12px 20px;
  height: 60px;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  font-family: "HelveticaNeueCyr-Medium";
  font-size: 18px;
  line-height: 24px;
  color: #060606;
  cursor: pointer;
}

.border-gradient-40 {
  position: relative;
  border-radius: 40px;
  background: #1e1e1e;
  z-index: 0;
}

.border-gradient-40::before {
  content: "";
  background: #fff;
  border-radius: 38px;
  position: absolute;
  top: 1.4px;
  left: 1.4px;
  bottom: 1.4px;
  right: 1px;
  z-index: -1;
}

/* chose__connection END */

/* info__window  */
.info__window {
  width: 328px;
  padding: 28px;
  font-family: "HelveticaNeueCyr-Romans", sans-serif;
  background: #fefefe;
  border-radius: 40px;
  box-shadow: 0px 20px 64px rgb(0 0 0 / 10%);
  text-align: center;

  margin: 0 auto;

  /* position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate3d(-50%, -50%, 0); */
}

.info__window-title {
  margin: 36px 0 15px;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #060606;
}

.info__window-text {
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 65px;
}

.info__window-text span {
  font-weight: 700;
}

/* You are buying */
.headphone__model {
  display: flex;
  padding: 8px 9px;
  align-items: center;
  justify-content: space-between;
}

.headphone__model-img {
  background: linear-gradient(180deg, #fdf7e9 0%, #cfc6ab 103.7%);
  border-radius: 50%;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headphone__model-text {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-left: 15px;
}

.headphone__model-text-name {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 3px;
}

.headphone__model-text-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #adadad;
}

.headphone__model-price {
  margin-right: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.border-gradient-40-2 {
  position: relative;
  border-radius: 40px;
  background:  #1e1e1e;
  z-index: 0;
}

.border-gradient-40-2::before {
  content: "";
  background: #fff;
  border-radius: 38px;
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  z-index: -1;
}

.transfer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.transfer__bank {
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-family: "HelveticaNeueCyr-bold";
}

.transfer__bank-name {
  flex: 1 1 auto;
  margin-left: 15px;
}

.transfer p {
  margin-top: 10px;
  font-family: "HelveticaNeueCyr-Medium";
}

.transfer p span {
  /* font-weight: 600; */
  font-size: 14px;
  line-height: 17px;
  color: #3aaf5c;
  margin-left: 10px;
  font-family: "HelveticaNeueCyr-Medium", "Poppins";
}

.transfer__protected {
  padding: 9px 10px;
  display: flex;
  justify-content: center;
  width: 230px;
  border: 1px solid #e6e6e6;
  border-radius: 40px;
  margin: 30px auto;
  font-size: 12px;
  line-height: 14px;
}

.transfer__protected img {
  margin-right: 8px;
}

.transfer__protected a {
  margin-left: 8px;
  color: #3aaf5c;
  position: relative;
}

.transfer__protected a::before {
  content: "";
  position: absolute;
  height: 1px;
  bottom: 2px;
  left: 0;
  right: 0;
  background-color: #3aaf5c;
}

.block__instructions {
  padding: 20px 15px;
  background: #f6f6f6;
  border-radius: 25px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 11px;
  font-family: "HelveticaNeueCyr-Medium";
  margin-bottom: 32px;
}

.block__instructions span {
  font-family: "HelveticaNeueCyr-bold";
  margin-right: 4px;
}

.checkbox__transfer {
  font-size: 14px;
  line-height: 17px;
  font-family: "HelveticaNeueCyr-bold";
  /* font-family: 'HelveticaNeueCyr-Medium'; */
  text-align: center;
  margin-bottom: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#transfer_in_one_amount {
  scale: 1.2;
}

.checkbox__transfer label {
  margin-left: 10px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* You are buying END */

/* You are buying 2 */
.big-text {
  width: 90%;
  font-size: 26px;
  line-height: 32px;
  font-family: "HelveticaNeueCyr-bold";
  text-align: center;
  margin: 0 auto;
}

.chose__screen-text {
  text-align: center;
  font-size: 16px;
  color: #2e2e2e;
  line-height: 19px;
}

.big-text span {
  background: #1e1e1e;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-family: "HelveticaNeueCyr-bold", "Poppins";
}

/* You are buying 2 END */

/* info__window END */
@media screen and (max-width: 400px) {
  .chose__screen {
    width: 320px;
    padding: 30px 8px;
  }

  .chose__connection {
    width: 320px;
    padding: 40px 8px;
  }

  .info__window {
    width: 300px;
  }
}

/* ============= */
.box {
  position: relative;
  width: 200px;
  height: 200px;
  background: #dedede;
  border-radius: 8px;
  overflow: hidden;
  color: #fff;
  margin: 0 auto;
  min-height: 200px;
  min-width: 200px;
  text-align: center;
  color: #000;
  margin-top: 40px;
}

.box::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200px;
  height: 200px;
  background: #1e1e1e;
  z-index: 1;
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
  min-height: 200px;
  min-width: 200px;
}

.box::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200px;
  height: 200px;
  background: #1e1e1e;
  z-index: 1;
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
  animation-delay: -3s;
  min-height: 200px;
  min-width: 200px;
  color: #000;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.box__content {
  position: absolute;
  inset: 3px;
  background: #dedede;
  border-radius: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 40px;
}

.box__content h2 {
  font-weight: 600;
}

/* ============= */
